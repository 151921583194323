import { useTheme } from "../context/ThemeContext";
// import ThemeSwitch from "../components/ThemeSwitch";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutImg from "../assets/images/about.jpg";
import "../styles/pages.css";
import { Link } from "react-router-dom";
const About = () => {
  const { activeColors } = useTheme();

  return (
    <div
      className="about"
      style={{
        backgroundColor: activeColors.bgPrimary,
        color: activeColors.primary,
      }}
    >
      <div className="img">
        <img src={AboutImg} alt="about" />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="about_text1"
          style={{ backgroundColor: activeColors.bgSecondary }}
        >
          Close your eyes for a moment and imagine an app that brings all your
          friends and parties together in one place. Picture effortlessly
          sharing all kinds of parties, from epic house parties to local bar
          gigs, and attending them with your friends without the hassle of
          organizing everything yourself. With this app, you and your friends
          can easily choose the perfect party based on your
          preferences—location, time, and distance.
        </div>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}
      >
        <div
          className="about_text1"
          style={{ backgroundColor: activeColors.bgSecondary }}
        >
          This is exactly what{" "}
          <Link style={{ color: "#49DD96", fontWeight: "bold" }}>troop</Link>{" "}
          was designed for. Backed by{" "}
          <Link
            to="https://www.linkedin.com/in/gideon-markman-7454854?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
            style={{ color: "#49DD96", fontWeight: "bold" }}
          >
            Professor Gideon Markman
          </Link>{" "}
          and{" "}
          <Link
            to="https://www.linkedin.com/company/institute-for-entrepreneurship-college-of-business/"
            style={{ color: "#49DD96", fontWeight: "bold" }}
          >
            Colorado State University I4E
          </Link>
          , troop is your ultimate social sidekick, making it easier than ever
          to connect with your crew and dive into the best events around town.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
