import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import ThemeProvider from "./context/ThemeContext";
import About from "./pages/About";
import Downloads from "./pages/downloads";
import Design from "./pages/design";
import Event from "./pages/event";
import Nav from "./components/Nav";
import { app } from "./services/firebase";
import AuthProvider from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import CheckOutSuccess from "./pages/CheckOutSuccess";
import CheckOutCancelled from "./pages/CheckOutCancelled";

function App() {
  useEffect(() => {
    const currentUrl = window.location.href;
    let newUrl;

    if (!currentUrl.includes("/download") && !currentUrl.includes("/design")) {
      if (currentUrl.includes("chat/")) {
        const startIndex = currentUrl.indexOf("chat/") + "chat/".length;
        const path = currentUrl.substring(startIndex);
        newUrl = `troopmain://chat/${path}`;
      } else if (currentUrl.includes("groupchat/")) {
        const startIndex =
          currentUrl.indexOf("groupchat/") + "groupchat/".length;
        const path = currentUrl.substring(startIndex);
        newUrl = `troopmain://groupchat/${path}`;
      } else if (currentUrl.includes("notifications")) {
        newUrl = `troopmain://notifications`;
      } else if (currentUrl.includes("payment/")) {
        const startIndex = currentUrl.indexOf("payment/") + "payment/".length;
        const message = currentUrl.substring(startIndex);
        newUrl = `troopmain://payment/${message}`;
      } else if (currentUrl.includes("userprofile/")) {
        const startIndex =
          currentUrl.indexOf("userprofile/") + "userprofile/".length;
        const userId = currentUrl.substring(startIndex);
        newUrl = `troopmain://userprofile/${userId}`;
      } else if (currentUrl.includes("users/")) {
        const startIndex = currentUrl.indexOf("users/") + "users/".length;
        const username = currentUrl.substring(startIndex);
        newUrl = `troopmain://users/${username}`;
      } else if (currentUrl.includes("events/")) {
        const startIndex = currentUrl.indexOf("events/") + "events/".length;
        const eventId = currentUrl.substring(startIndex);
        newUrl = `troopmain://events/${eventId}`;
      }

      if (newUrl) {
        window.location.href = newUrl;
      }
    }
  }, []);

  // if (currentUrl.includes("events/")) {
  //   const startIndex = currentUrl.indexOf("events/") + "events/".length;
  //   const eventId = currentUrl.substring(startIndex);
  //   newUrl = `troopmain://events/${eventId}`;
  // } else
  return (
    <ThemeProvider>
      <AuthProvider>
        <BrowserRouter>
          <>
            <Nav />
            <Toaster containerClassName="!z-[999999999]" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/redirects" element={<Home />} />
              <Route exact path="*" element={<Home />} />
              <Route exact path="/download" element={<Downloads />} />
              <Route exact path="/design" element={<Design />} />
              <Route
                exact
                path="/checkout-success/:id"
                element={<CheckOutSuccess />}
              />
              <Route
                exact
                path="/checkout-cancelled/:id"
                element={<CheckOutCancelled />}
              />
              <Route exact path="/events/:id" element={<Event />} />
            </Routes>
          </>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
