import React, { useEffect, useState } from "react";
import { Expand } from "@theme-toggles/react";
import "@theme-toggles/react/css/Expand.css";
import "../styles/test.css"; // Import your custom CSS

const ThemeSwitch = ({ isDarkTheme, toggleThemeMode }) => {
  const [animate, setAnimate] = useState(false);

  const handleToggle = () => {
    setAnimate(true);
    toggleThemeMode();
  };

  useEffect(() => {
    if (animate) {
      const timeout = setTimeout(() => {
        setAnimate(false);
      }, 800); // Match the duration with your animation time

      return () => clearTimeout(timeout);
    }
  }, [animate]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Expand
        toggled={isDarkTheme}
        toggle={handleToggle}
        duration={750}
        style={{
          fontSize: "2.1rem", // Adjust size here
          color: isDarkTheme ? "#FFFFFF" : "#000000", // Icon color based on theme
          zIndex: 99999,
        }}
        moonColor="#FFFFFF" // Moon icon color for dark mode
        sunColor="#000000" // Sun icon color for light mode
      />
      {animate && (
        <div
          className={`circle-animation z-[99999] ${
            isDarkTheme ? "circle-dark" : "circle-light"
          }`}
          style={{
            top: "50%",
            left: "50%",
            position: "absolute",
          }}
        />
      )}
    </div>
  );
};

export default ThemeSwitch;
