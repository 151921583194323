import React from "react";
import ThemeSwitch from "./ThemeSwitch";
import Navbar from "./Navbar";
import { useTheme } from "../context/ThemeContext";

const Nav = () => {
  const { toggleThemeMode, activeColors, isDarkTheme } = useTheme();
  return (
    <div
      style={{
        backgroundColor: activeColors.bgPrimary,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Navbar />
      <div
        className="theme_switch z-[99999]"
        style={{ position: "absolute", right: 70 }}
      >
        <ThemeSwitch
          isDarkTheme={isDarkTheme}
          toggleThemeMode={toggleThemeMode}
          activeColors={activeColors}
        />
      </div>
    </div>
  );
};

export default Nav;
