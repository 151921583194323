import { ArrowLeft, XCircleIcon } from "lucide-react";
import React from "react";
import { Link, useParams } from "react-router-dom";

const CheckOutCancelled = () => {
  const { id } = useParams();

  return (
    <div className="min-h-[80vh] flex items-center justify-center p-5 text-center">
      <div>
        <div className="flex flex-col items-center gap-3">
          <XCircleIcon color="red" size={70} />
          <h1 className="text-3xl max-md:text-2xl font-bold mb-3">
            Event Ticket Purchase Cancelled{" "}
          </h1>
        </div>
        <Link
          className="text-[var(--secondary)] text-center"
          to={`/events/${id}`}
        >
          <p className="flex items-center gap-2 justify-center">
            <ArrowLeft size={19} /> Back to event
          </p>
        </Link>
      </div>
    </div>
  );
};

export default CheckOutCancelled;
