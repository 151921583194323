import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_TROOP_API_KEY,
  authDomain: process.env.REACT_TROOP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_TROOP_DATABASE_URL,
  projectId: process.env.REACT_TROOP_PROJECT_ID,
  storageBucket: process.env.REACT_TROOP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_TROOP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_TROOP_APP_ID,
  measurementId: process.env.REACT_TROOP_MEASUREMENTID,
};

export const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV == "development") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});
export const db = getFirestore(app);
