export function convertToFullFormat(dateString, timeString) {
  const dateTimeString = `${dateString}T${timeString}`;

  const date = new Date(dateTimeString);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export const formatTime = (timeString) => {
  if (isTimestamp(timeString)) {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  } else {
    const dateTime = parseTimeStringToDateTime(timeString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(dateTime);
  }
};

export const formatDate = (dateString, short) => {
  if (isTimestamp(dateString)) {
    const date = new Date(dateString);
    const options = short
      ? { month: "short", day: "2-digit" }
      : { month: "short", day: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  } else {
    const [year, month, day] = dateString?.split("-");
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    const monthAbbreviation = months[parseInt(month) - 1];

    return short
      ? `${monthAbbreviation} ${parseInt(day)}`
      : `${monthAbbreviation} ${parseInt(day)}, ${parseInt(year)}`;
  }
};

function isTimestamp(value) {
  if (typeof value !== "number") {
    return false;
  }

  const date = new Date(value);
  return !isNaN(date.getTime());
}

export const parseTimeStringToDateTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":");
  const currentDate = new Date();
  currentDate.setHours(parseInt(hours, 10));
  currentDate.setMinutes(parseInt(minutes, 10));
  currentDate.setSeconds(parseInt(seconds, 10));
  return currentDate;
};

export const getIpAddress = async () => {
  try {
    const response = await fetch("https://api.country.is/");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};

export function generateRandomUsernameFromEmail(email) {
  if (email) {
    const username = email.split("@")[0];
    const randomNumber = Math.floor(Math.random() * 1000);
    return `${username}${randomNumber}`;
  }
}

export function convertToDirectionsUrl(googleMapsUrl) {
  const url = new URL(googleMapsUrl);

  if (url.searchParams.has("q")) {
    const query = url.searchParams.get("q");
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      query
    )}`;
  } else {
    return googleMapsUrl;
  }

  // return "Invalid URL. No recognizable parameters found.";
}
