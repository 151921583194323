// import { useTheme } from "../context/ThemeContext";
import "../styles/components.css";
import Download from "../assets/images/download.png";
import { Link } from "react-router-dom";
import AppleLogo from "../assets/images/apple-logo.png";
const BottomDownload = () => {
  // const { activeColors } = useTheme();
  return (
    <div
      className="bottom_download max-md:!pt-14 !px-8"
      style={{ backgroundColor: "#14CD8A" }}
    >
      <div className="">
        <h1 className="text-5xl max-md:text-3xl !text-black font-semibold mb-4">
          Download and Start Making <br /> Real Memories!
        </h1>
        <Link
          style={{ textDecoration: "none" }}
          to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192"
        >
          <button
            className="border-2 border-black flex items-center gap-4 p-2 px-4 rounded-full mt-7"
            style={{ backgroundColor: "#14CD8A", color: "black" }}
          >
            <img src={AppleLogo} width={20} alt="apple logo" />{" "}
            <p className="text-lg">Download</p>
          </button>
        </Link>
      </div>
      <div className="right">
        {/* <FadeInSection> */}
        <img src={Download} alt="download" />
        {/* </FadeInSection> */}
      </div>
    </div>
  );
};

export default BottomDownload;
