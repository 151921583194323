import React, { useState, useEffect, useRef } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../services/firebase";
import { useClickAway } from "react-use";
import { generateRandomUsernameFromEmail } from "../utils/helper";
import { useAuth } from "../context/AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { Loader2, TicketIcon } from "lucide-react";

const sendEmail = async (to, subject, body) => {
  try {
    console.log(`Sending email to ${to}`);
    console.log(`Subject: ${subject}`);
    console.log(`Body: ${body}`);

    await addDoc(collection(db, "mail"), {
      to,
      message: {
        subject,
        html: body,
      },
    });

    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email: ", error);
  }
};

const generateRandomPassword = () => {
  return Math.random().toString(36).slice(-8);
};

export default function EventTicketModal({
  isOpen,
  setIsOpen,
  event,
  isAdded,
  id,
}) {
  const { isLoggedIn, userID, fullUserDetails, appCheckToken, handleSignOut } =
    useAuth();
  const [currentStep, setCurrentStep] = useState("personal");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState("");

  const modalRef = useRef();

  useClickAway(modalRef, () => setIsOpen(false));

  useEffect(() => {
    if (fullUserDetails) {
      setName(fullUserDetails.name || "");
      setEmail(fullUserDetails.email || "");
    }
  }, [fullUserDetails]);

  const handleClose = () => {
    setIsOpen(false);
    setCurrentStep("personal");
  };

  const checkUserExists = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));

    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handlePersonalInfoSubmit = async (e) => {
    e.preventDefault();
    const PhotoUrL =
      "https://firebasestorage.googleapis.com/v0/b/troop-88fe2.appspot.com/o/user%20(1).png?alt=media&token=2365f2c3-2996-4137-bd24-9695dfbbcaa0";

    if (isLoggedIn) {
      setCurrentStep("payment");
      return;
    }

    try {
      setIsLoading(true);
      const userExists = await checkUserExists(email);

      if (userExists) {
        setCurrentStep("password");
        setIsLoading(false);
      } else {
        const confirm = window.confirm(
          `This email, "${email}", will be used to create an account on troop, do you wish to continue with it?`
        );
        if (confirm) {
          const newPassword = generateRandomPassword();
          const user = await createUserWithEmailAndPassword(
            auth,
            email,
            newPassword
          );
          await setDoc(doc(db, "users", user.user.uid), {
            uid: user.user.uid,
            name,
            authProvider: "local",
            email,
            photoURL: PhotoUrL,
            joinedNotificationBox: [],
            username: generateRandomUsernameFromEmail(email),
            name_lowercase: name.toLowerCase(),
            isVerified: false,
          });
          toast.success("A password has been sent to your mail");
          toast.success(
            "Welcome to Troop, an account has been created for you! 🎉"
          );
          await sendEmail(
            email,
            "Welcome to Troop! 🎉🎊",
            `Hi ${name},
           Here is your password: <b>${newPassword}</b>`
          );
          setIsLoading(false);
          setCurrentStep("payment");
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      toast.error("An error occurred. Please try again.");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoading(false);
      setCurrentStep("payment");
    } catch (error) {
      toast.error("Invalid email or password");
    }
  };

  async function handleIncrement() {
    try {
      setIsLoading(true);
      // Event document reference
      const eventRef = doc(db, "eventPost", id);

      // Attendees collection reference under the event
      const eventTakenByRef = collection(eventRef, "attendees");

      // Query to find if the user has already joined
      const q = query(eventTakenByRef, where("uid", "==", userID));
      const eventTakenByDocs = await getDocs(q);

      // Get event document data
      const eventDoc = await getDoc(eventRef);

      const userIDs = eventDoc.data()?.userID;
      const currentTakenBy = eventDoc?.data()?.takenBy || [];
      const spotTaken = eventDoc?.data()?.spotTaken;
      const spotLimit = eventDoc?.data()?.spot;

      const spotLimitNumber = parseInt(spotLimit, 10);

      console.log("spotTaken", spotTaken);
      console.log("spotLimitNumber", spotLimit);

      if (spotTaken >= spotLimitNumber && !eventDoc?.data().isUnlimited) {
        toast.error("Sorry! Event already filled up 😔");
        setIsLoading(false);
        return;
      } else if (currentTakenBy.includes(userID)) {
        toast.success("You have already joined this party 🎉");
        setIsLoading(false);
        return;
      } else if (!eventTakenByDocs.empty) {
        toast.success("You have already joined this party 🎉");
        setIsLoading(false);
        return;
      } else {
        if (!event.isFree) {
          event.country === "Nigeria"
            ? initializePaystack()
            : initializeStripe(event);
        } else {
          // Increment the spotTaken count
          await updateDoc(eventRef, {
            spotTaken: increment(1),
          });

          // Add the user to the attendees collection
          await addDoc(eventTakenByRef, { uid: userID });

          toast.success("Yay! You joined the party 🎉");
          setIsLoading(false);

          // Add a notification for the event owner
          await addDoc(collection(db, "notifications"), {
            userDetails: {
              photoURL: fullUserDetails.photoURL,
              name: fullUserDetails.name,
              uid: userID,
            },
            message: "joined your event",
            to: userIDs,
            hasButton: false,
            sentAt: Date.now(),
          });

          // Update unread notifications count for the event owner
          await updateDoc(doc(db, "users", userIDs), {
            unreadNotifications: increment(1),
          });

          const serverEndpoint =
            "https://troop-node-notification.onrender.com/joined-event";
          await axios.post(
            serverEndpoint,
            {
              userId: userIDs,
              eventId: id,
              name: fullUserDetails.name,
            },
            {
              headers: {
                Accept: "application/json",
                "X-Firebase-AppCheck": appCheckToken,
              },
            }
          );

          // Schedule event notification
          await axios.post(
            "https://troop-node-notification.onrender.com/schedule-event-notification",
            {
              eventId: id,
              userId: userID,
            },
            {
              headers: {
                Accept: "application/json",
                "X-Firebase-AppCheck": appCheckToken,
              },
            }
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating event spots", error);
    }
  }

  const [loadingStripe, setLoadingStripe] = useState(false);
  const initializeStripe = async () => {
    if (event.tickets && !selectedTicket) {
      toast.error("Select a ticket to continue");
      setLoadingStripe(false);
      setIsLoading(false);
    } else {
      try {
        setLoadingStripe(true);

        if (!fullUserDetails.customerId) {
          const res = await axios.post(
            `https://troop-node-payment.onrender.com/get-secret`,
            {
              name: fullUserDetails.name,
              email: fullUserDetails.email,
            },
            {
              headers: {
                Accept: "application/json",
                "X-Firebase-AppCheck": appCheckToken,
              },
            }
          );
        }

        const response = await axios.post(
          `https://troop-node-payment.onrender.com/checkout-session`,
          selectedTicket
            ? {
                amount: selectedTicket.price,
                stripeCustomerId: fullUserDetails.customerId,
                eventId: id,
                userId: userID,
                hostId: event.userID,
                fullUserDetails: {
                  name: fullUserDetails.name,
                  photoURL: fullUserDetails.photoURL,
                },
                ticketName: selectedTicket.name,
                eventName: event.title.trim(),
              }
            : {
                amount: event.ticketAmount,
                stripeCustomerId: fullUserDetails.customerId,
                eventId: id,
                userId: userID,
                hostId: event.userID,
                fullUserDetails: {
                  name: fullUserDetails.name,
                  photoURL: fullUserDetails.photoURL,
                },
                eventName: event.title.trim(),
              },
          {
            headers: {
              Accept: "application/json",
              "X-Firebase-AppCheck": appCheckToken,
            },
          }
        );
        const { session_url } = response.data;

        const paymentWindow = window.open(session_url);

        if (paymentWindow) {
        } else {
          console.error("Failed to open payment window.");
        }
      } catch (error) {
        toast.error("An error occured");
        setIsLoading(false);
        setLoadingStripe(false);
      }
    }
  };

  const [loadingPaystack, setLoadingPaystack] = useState(false);
  const initializePaystack = async () => {
    if (event.tickets && !selectedTicket) {
      toast.error("Select a ticket to continue");
      setLoadingPaystack(false);
      setIsLoading(false);
    } else {
      try {
        setLoadingPaystack(true);
        const response = await axios.post(
          `https://troop-node-payment-wa.onrender.com/initialize-payment`,
          selectedTicket
            ? {
                amount: selectedTicket.price,
                eventId: id,
                userId: userID,
                hostId: event.userID,
                name: fullUserDetails.name,
                photoURL: fullUserDetails.photoURL,
                email: fullUserDetails.email,
                ticketName: selectedTicket.name,
              }
            : {
                amount: event.ticketAmount,
                eventId: id,
                userId: userID,
                hostId: event.userID,
                name: fullUserDetails.name,
                photoURL: fullUserDetails.photoURL,
                email: fullUserDetails.email,
              },
          {
            headers: {
              Accept: "application/json",
              "X-Firebase-AppCheck": appCheckToken,
            },
          }
        );
        const { authorization_url } = response.data.data;

        const paymentWindow = window.open(authorization_url);

        if (paymentWindow) {
        } else {
          console.error("Failed to open payment window.");
        }
      } catch (error) {
        toast.error("An error occured");
        setIsLoading(false);
        setLoadingPaystack(false);
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case "personal":
        return (
          <form onSubmit={handlePersonalInfoSubmit} className="space-y-4">
            {isLoggedIn && fullUserDetails ? (
              <div className="my-10">
                <div className="flex items-center gap-3">
                  <img
                    src={fullUserDetails.photoURL}
                    className="w-[40px] h-[40px] bg-[var(--secondary)] rounded-md"
                  />
                  <div>
                    <p>{fullUserDetails.name}</p>
                    <p className="text-sm opacity-50">
                      {fullUserDetails.email}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium opacity-50"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    disabled={isLoggedIn}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 p-2 block w-full bg-[var(--bg-secondary)] rounded-md border-gray-300 shadow-sm outline-none disabled:opacity-50"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium opacity-50"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    disabled={isLoggedIn}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 p-2 block w-full bg-[var(--bg-secondary)] rounded-md border-gray-300 shadow-sm outline-none disabled:opacity-50"
                  />
                </div>
              </>
            )}
            <div className="flex items-center gap-2">
              {isLoggedIn && fullUserDetails && (
                <button
                  onClick={() => {
                    setCurrentStep("personal");
                    handleSignOut();
                  }}
                  className="w-full bg-[var(--secondary)] text-black py-2 px-4 rounded-md hover:opacity-50 text-sm"
                >
                  Change details
                </button>
              )}
              <button
                disabled={isLoading}
                type="submit"
                className="w-full bg-[var(--secondary)] text-black py-2 px-4 rounded-md hover:opacity-50 text-sm flex items-center gap-3 justify-center disabled:opacity-50"
              >
                {isLoading && <Loader2 size={15} className="animate-spin" />}{" "}
                {isLoggedIn && fullUserDetails ? "Proceed" : "Next"}
              </button>
            </div>
          </form>
        );
      case "password":
        return (
          <form onSubmit={handlePasswordSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium opacity-50"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 p-2 block w-full bg-[var(--bg-secondary)] rounded-md border-gray-300 shadow-sm outline-none"
              />
            </div>
            <button
              disabled={isLoading}
              type="submit"
              className="w-full bg-[var(--secondary)] text-black py-2 px-4 rounded-md hover:opacity-50 text-sm flex items-center gap-3 justify-center disabled:opacity-50"
            >
              {isLoading && <Loader2 size={15} className="animate-spin" />} Next
            </button>
          </form>
        );
      case "payment":
        return (
          <div className="space-y-4">
            <p className="text-base font-medium">
              {event.isFree
                ? "Ready to Join this event?"
                : event.tickets
                ? "Select a ticket to purchase"
                : "Ready to complete your purchase?"}
            </p>
            {event.tickets && (
              <div>
                {event.tickets.map((ticket, i) => {
                  return (
                    <div
                      onClick={() => setSelectedTicket(ticket)}
                      className={`flex items-center mb-2 gap-2 cursor-pointer ${
                        selectedTicket == ticket && "text-black"
                      }`}
                      key={i}
                    >
                      <div
                        className={`flex ${
                          selectedTicket == ticket
                            ? "bg-[var(--secondary)]"
                            : "bg-[var(--bg-secondary)]"
                        }  rounded p-2 px-3 w-[80%] gap-3`}
                      >
                        <TicketIcon />
                        <p className="uppercase">{ticket.name}</p>
                      </div>
                      <p
                        className={`flex ${
                          selectedTicket == ticket
                            ? "bg-[var(--secondary)]"
                            : "bg-[var(--bg-secondary)]"
                        } p-2 px-3 flex-1 text-center rounded justify-center items-center`}
                      >
                        {event.country == "Nigeria" ? "₦" : "$"}
                        {ticket.price}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            <button
              disabled={isLoading || loadingPaystack || loadingStripe}
              onClick={handleIncrement}
              className="w-full bg-[var(--secondary)] text-black py-2 px-4 rounded-md hover:opacity-50 text-sm flex items-center gap-3 justify-center disabled:opacity-50"
            >
              {(isLoading || loadingPaystack || loadingStripe) && (
                <Loader2 size={15} className="animate-spin" />
              )}{" "}
              {event.isFree ? "Join" : "Pay Now"}
            </button>
          </div>
        );
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed z-[99999999] inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div
            ref={modalRef}
            className="bg-[var(--bg-primary)] rounded-lg shadow-xl p-6 w-full max-w-md"
          >
            <h2 className="text-xl font-bold mb-4">
              {event.isFree ? "Join Event" : "Buy Ticket"}
            </h2>
            {Date.now() > event.endTime ? (
              <p className="p-3 px-4 font-semibold rounded-md bg-[var(--bg-secondary)]">
                This event has ended
              </p>
            ) : (
              <>
                <div className="flex justify-between mb-6 gap-4">
                  {isLoggedIn
                    ? ["personal", "payment"].map((step, index) => (
                        <div
                          key={step}
                          className={`flex-1 h-2 rounded-full ${
                            currentStep === step
                              ? "bg-[var(--secondary)]"
                              : "bg-[var(--primary)] opacity-10"
                          }`}
                        />
                      ))
                    : ["personal", "password", "payment"].map((step, index) => (
                        <div
                          key={step}
                          className={`flex-1 h-2 rounded-full ${
                            currentStep === step
                              ? "bg-[var(--secondary)]"
                              : "bg-[var(--primary)] opacity-10"
                          }`}
                        />
                      ))}
                </div>
                {isAdded ? (
                  <>
                    <p className="p-3 px-4 font-semibold rounded-md bg-[var(--bg-secondary)]">
                      You are already an attendee of this event
                    </p>
                    {isLoggedIn && fullUserDetails && (
                      <button
                        onClick={() => {
                          setCurrentStep("personal");
                          handleSignOut();
                        }}
                        className="w-full bg-[var(--secondary)] text-black py-2 px-4 rounded-md hover:opacity-50 text-sm mt-3"
                      >
                        Change details
                      </button>
                    )}
                  </>
                ) : (
                  renderStep()
                )}
              </>
            )}
            <button
              onClick={handleClose}
              className="mt-4 text-sm text-gray-600 hover:text-gray-800"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
