import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useTheme } from "../context/ThemeContext";
import { db } from "../services/firebase";
import {
  convertToDirectionsUrl,
  convertToFullFormat,
  formatDate,
  formatTime,
} from "../utils/helper";
import {
  Clock,
  LinkIcon,
  Loader,
  LoaderCircle,
  Pin,
  Plus,
  Ticket,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import EventTicketModal from "../components/JoinEventModal";

const Event = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeColors } = useTheme();
  const { region, userID } = useAuth();
  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    if (id) {
      const eventRef = doc(db, "eventPost", id);

      const unsubscribe = onSnapshot(
        eventRef,
        (eventSnap) => {
          if (eventSnap.exists()) {
            setEvent({ id: eventSnap.id, ...eventSnap.data() });
            setLoading(false);
          } else {
            setError("Event not found");
            setLoading(false);
          }
        },
        (error) => {
          setError("Error fetching event");
          setLoading(false);
          console.error("Error fetching event:", error);
        }
      );

      return () => unsubscribe();
    }
  }, [id]);

  useEffect(() => {
    if (id && userID) {
      const eventDocRef = doc(db, "eventPost", id);
      const eventRef = collection(eventDocRef, "attendees");
      const q = query(eventRef, where("uid", "==", userID));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          setIsAdded(!querySnapshot.empty);
          console.log(querySnapshot.empty, userID);
        },
        (error) => {
          console.error("Error fetching event:", error);
        }
      );

      return () => unsubscribe();
    } else {
      setIsAdded(false);
    }
  }, [id, userID]);

  if (loading) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center gap-3 text-lg">
        <p>Loading Event...</p>
        <LoaderCircle className="animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center p-5">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">{error}</h2>
          <p className="max-md:text-sm">
            Sorry, we couldn't find the event you're looking for.
          </p>
        </div>
      </div>
    );
  }

  if (event.country !== region) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center p-5">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">
            Event can't be viewed in your location
          </h2>
          <p className="max-md:text-sm">
            Sorry, this event can't be viewed in your location
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {isModalOpen && (
        <EventTicketModal
          event={event}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          isAdded={isAdded}
          id={id}
        />
      )}
      <header className="relative h-[50vh] overflow-hidden">
        <img
          src={
            event.imageUrl ||
            "https://media.istockphoto.com/id/501387734/photo/dancing-friends.jpg?s=612x612&w=0&k=20&c=SoTKXXMiJYnc4luzJz3gIdfup3MI8ZlROFNXRBruc10="
          }
          alt="Event background"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/70 to-black flex flex-col justify-end items-center text-center p-6">
          <div className="flex flex-col justify-end items-center text-center max-w-3xl">
            <h2 className="text-lg md:text-xl font-semibold text-white/50">
              {event.theme}
            </h2>
            <h1 className="text-4xl md:text-5xl font-bold text-white">
              {event.title}
            </h1>
            <h2 className="text-base md:text-lg font-base text-white/60 mt-1">
              {formatDate(event.date, true)} |{" "}
              {
                event.placeDescription.split(",")[
                  event.placeDescription.split(",").length - 2
                ]
              }
            </h2>

            <div className="mt-5 flex items-center gap-3">
              {!event.tickets && (
                <p className="h-max p-3 px-4 rounded bg-[var(--bg-primary)] font-semibold">
                  {" "}
                  {event.isFree
                    ? "Free"
                    : `${event.country == "Nigeria" ? "₦" : "$"}${
                        event.ticketAmount
                      }`}
                </p>
              )}
              {Date.now() > event.endTime ? (
                <p className="font-bold">Event has Ended</p>
              ) : (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-[var(--secondary)] hover:opacity-90 text-black font-semibold py-3 px-5 md:px-7 rounded w-full flex items-center gap-3"
                >
                  {event.isFree ? <Plus /> : <Ticket />}
                  {event.isFree ? "Join Event" : "Buy Ticket"}
                </button>
              )}
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-2/3">
            <h3 className="text-2xl font-semibold mb-4">Event Description</h3>
            <p className="mb-4">{event.description}</p>
          </div>
          <div className="md:w-1/3">
            <div className="bg-[var(--bg-secondary)] p-5 rounded-lg mb-4">
              <div className="flex items-center gap-2 mb-2">
                <Clock size={18} className="text-[var(--secondary)]" />
                <h4 className="font-semibold text-lg">Date and Time</h4>
              </div>

              <p className="text-sm">
                {formatDate(event.date)} at {formatTime(event.time)}
              </p>
            </div>
            <div className="bg-[var(--bg-secondary)] p-5 rounded-lg mb-4">
              <div className="flex items-center gap-2 mb-2">
                <Pin size={18} className="text-[var(--secondary)]" />
                <h4 className="font-semibold text-lg">Location</h4>
              </div>

              <p className="text-sm mb-2">{event.placeDescription}</p>

              <a
                href={convertToDirectionsUrl(event.placeURL)}
                target="_blank"
                className="text-[var(--secondary)] hover:opacity-50 w-full text-right right-0"
              >
                View Map
              </a>
            </div>
          </div>
        </div>
      </main>
      <div className="p-5 border-t border-t-[var(--bg-secondary)] bg-[var(--bg-primary)]  sticky bottom-0 z-[9999] ">
        <div className="container md:px-5 mx-auto flex items-center justify-end gap-10">
          <a href={`troopmain://events/${id}`}>
            <p className="bg-[var(--secondary)] hover:opacity-90 text-black font-semibold py-2 px-5 rounded flex items-center gap-3">
              <LinkIcon /> Open event on App
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Event;
