import { useTheme } from "../context/ThemeContext";
import ThemeSwitch from "../components/ThemeSwitch";
import Navbar from "../components/Navbar";
// import LandingPage from "../components/LandingPage";
import Middle1 from "../assets/images/middle1.jpg";
import Middle2 from "../assets/images/middle2.jpg";
import Middle3 from "../assets/images/middle3.jpg";
import Middle4 from "../assets/images/middle4.jpg";

import "../styles/pages.css";
// import ProductDisplay from "../components/ProductDisplay";
import BottomDownload from "../components/BottomDownload";
import Footer from "../components/Footer";
const Home = () => {
  const { toggleThemeMode, activeColors, isDarkTheme } = useTheme();

  return (
    <div>
      <div className="progress"></div>

      {/* <LandingPage /> */}
      <div
        className="middle_page gap-20 !py-20"
        style={{
          backgroundColor: activeColors.bgSecondary,
          color: activeColors.primary,
        }}
      >
        <div className="">
          <h1 className="text-7xl max-md:text-5xl max-md:w-[270px] w-[500px] border-b-[6px] border-b-[var(--secondary)] mb-3 pb-2">
            Improve your social life
          </h1>
          <text className="max-md:text-base opacity-50">
            By actually going outside!
          </text>
        </div>
        <div className=" grid grid-cols-2">
          <img src={Middle1} alt="middle 1" />
          <img src={Middle2} alt="middle 2" />

          <img src={Middle3} alt="middle 3" />
          <img src={Middle4} alt="middle 4" />
        </div>
      </div>
      {/* <ProductDisplay/> */}
      <BottomDownload />
      <Footer />
    </div>
  );
};

export default Home;
