import React, { createContext, useContext, useEffect, useState } from "react";
import { getIpAddress } from "../utils/helper";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { appCheck, auth, db } from "../services/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getToken } from "firebase/app-check";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [region, setRegion] = useState("");
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState(null);
  const [fullUserDetails, setFullUserDetails] = useState(null);
  const [appCheckToken, setAppCheckToken] = useState(null);

  const handleSignOut = async () => {
    setIsLoggedIn(false);
    setUser(null);
    setUserID(null);
    setFullUserDetails(null);
    signOut(auth);
  };

  useEffect(() => {
    const getAppCheckToken = async () => {
      try {
        const { token } = await getToken(appCheck, true);

        if (token.length > 0) {
          setAppCheckToken(token);
        }
      } catch (error) {
        console.log("AppCheck verification failed", error);
      }
    };

    getAppCheckToken();
  }, [appCheck]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setIsLoggedIn(true);
        setUser(currentUser);
        setUserID(currentUser.uid);
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setUserID(null);
        setFullUserDetails(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userID) {
      const fetchRealTimeUserDetails = () => {
        const q = query(collection(db, "users"), where("uid", "==", userID));

        return onSnapshot(q, (snapshot) => {
          if (!snapshot.empty) {
            setFullUserDetails(snapshot.docs[0].data());
          }
        });
      };

      const unsubscribe = fetchRealTimeUserDetails();

      return () => unsubscribe();
    }
  }, [userID]);

  const getRegion = async () => {
    const ipData = await getIpAddress();
    if (ipData) {
      const country =
        ipData.country == "NG"
          ? "Nigeria"
          : ipData.country == "US"
          ? "United States"
          : ipData.country;
      setRegion(country);
    }
  };

  useEffect(() => {
    getRegion();
  }, []);

  const value = {
    region,
    isLoggedIn,
    user,
    userID,
    fullUserDetails,
    appCheckToken,
    handleSignOut,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
