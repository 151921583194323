import { useState, useRef, useEffect } from "react";
import { useTheme } from "../context/ThemeContext";
import "../styles/components.css";
import Logo from "../assets/images/logo.png";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import ThemeSwitch from "./ThemeSwitch";

const Navbar = () => {
  const { activeColors, isDarkTheme, toggleThemeMode } = useTheme();
  const [isChanged, setIsChanged] = useState(false);
  const router = useLocation();

  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  const handleClick = () => {
    setIsChanged(!isChanged);
  };

  const routes = [
    {
      title: "Home",
      to: "/",
      // Icon: BiHomeAlt2,
    },
    {
      title: "Download",
      to: "https://apps.apple.com/us/app/troop-the-party-app/id6476594192",
      //Icon: FiSearch,
    },
    {
      title: "About",
      to: "/about",
      //Icon: IoPricetagsOutline,
    },
    {
      title: "Contact",
      to: "https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6",
      //Icon: PiChatCircleBold,
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        top: 0,
      }}
      className="z-[9999]"
    >
      <div className="navbar_desktop">
        <div
          className="navbar"
          style={{
            background: activeColors.bgSecondary,
            transition: "background-color 0.3s ease",
            width: isChanged ? "100%" : "lightblue",
          }}
        >
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to={"/"}
          >
            <img src={Logo} alt="troop logo" />
          </Link>
          <div style={{ marginRight: 30 }}>
            {" "}
            {routes.map((route) => {
              return (
                <Link
                  to={route.to}
                  style={{
                    color:
                      router.pathname == route.to
                        ? activeColors.secondary
                        : activeColors.primary,
                  }}
                >
                  {route.title}
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div className="navbar_mobile ">
        <div
          className="navbar_mobile_child max-md:!p-3"
          style={{ backgroundColor: activeColors.bgSecondary }}
          ref={ref}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to={"/"}>
              <img className="logo" src={Logo} alt="troop logo" />
            </Link>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
            >
              <div
                style={{
                  backgroundColor: activeColors.bgPrimary,
                  borderRadius: 100,
                  padding: 0,
                }}
              >
                <Hamburger
                  color={activeColors.primary}
                  toggled={isOpen}
                  size={23}
                  toggle={setOpen}
                />
              </div>
            </div>
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute bg-[var(--bg-primary)] left-0 right-0 mt-2 !top-[4rem] p-5 border-b border-b-white/20 pt-6 z-[99999]"
              >
                <ul style={{ color: activeColors.primary }} className="grid">
                  {routes.map((route, idx) => {
                    // const { Icon } = route;

                    return (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        key={route.title}
                        className="w-full text-lg"
                      >
                        <Link
                          style={{
                            color:
                              router.pathname == route.to
                                ? activeColors.secondary
                                : activeColors.primary,
                            textDecoration: "none",
                          }}
                          onClick={() => setOpen((prev) => !prev)}
                          className={"flex items-center justify-between w-full"}
                          to={route.to}
                        >
                          <span
                            style={{ marginBottom: 20 }}
                            className="flex gap-1"
                          >
                            {route.title}
                          </span>
                          {/* <Icon className="text-xl" /> */}
                        </Link>
                      </motion.li>
                    );
                  })}
                </ul>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      borderRadius: 100,
                      paddingTop: 1,
                      paddingBottom: -5,
                    }}
                  >
                    <ThemeSwitch
                      isDarkTheme={isDarkTheme}
                      toggleThemeMode={toggleThemeMode}
                      activeColors={activeColors}
                    />
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
